import { useDispatch, useSelector } from 'react-redux';
import { ID_SYSTEM_INFORMATION } from '../Settings/Constants/Constants';
import { HttpClient } from '../Utils/ApiUtilies/Fetch/Fetch';

export const FRONTENDCRM_URL = 'https://crm.business-litethinking.com/';
export const LOCATION_URL = 'https://ipapi.co/json/'; //get
// export const OFFICE_365_LOGIN = 'https://www.outlook.com/litethinking.com';
export const PREVIEW_URL = 'https://getlinkpreview.onrender.com/'; //post
export const API_CODE_VERIFICATION = '/api-code-verification/'; //post
// export const LOGIN_PATH = '/api-token/';
export const REGISTER_PATH = '/api-virtual-campus-users/'; //post patch
export const LOGIN_SOCIAL_PATH = '/api-social-auth/'; //post
export const COURSES_LIST_PATH = '/api-virtual-campus-courses/'; //get
export const LANGUAGE_LIST_PATH = '/api-multilanguage-list/'; //get
export const GET_API_BANNER_VIDEOS = '/api-virtual-campus-introductory-banner/'; //get
export const GENERAL_BANNER_LIST_PATH = '/api-virtual-campus-general-banner/'; //get
export const CONSULTANTS_LIST_PATH = '/api-virtual-campus-consultants/'; //get
// export const GET_PROFILE = '/api-virtual-campus-users/';
export const REGISTER_BUY_PATH = '/api-users-courses-modes';
export const GET_COUNTRIES = '/api-countries/'; //get
export const GET_COUNTRIES_MULTILANGUAJE = '/api-countries-multilanguaje/'; //get
export const GET_CITIES = '/api-cities/'; //get
export const GET_PARAMETERS_PATH = '/api-parameters/'; //get
// export const INDUSTRIES_API_PATH = '/api-virtual-campus-industries/';
export const COMPANIES_LIST_PATH = '/api-virtual-campus-companies/'; // get
export const REGISTER_USER_PATH = '/api-virtual-campus-user-register/'; //POST
export const DOCUMENTS_PATH = '/api-documents/'; //GET
export const GET_PARTNERS_LIST_PATH = '/api-virtual-campus-partners/'; //GET
export const GET_STRATEGIC_LINES_LIST_PATH = '/api-virtual-campus-strategic-lines/'; //GET
export const GET_PORTFOLIOS_LIST_PATH = '/api-virtual-campus-portfolios/'; //get
export const GET_NEWS_LIST_PATH = '/api-virtual-campus-news/'; //get
export const GET_CONSULTANTS_BY_COURSE = '/api-consultant-by-course/'; //get
export const GET_SCHEDULER_BY_COURSE = '/api-scheduler-by-course/'; //get
export const GET_RESOURCES_BY_COURSE = '/api-resources-by-course/'; //get
export const STRIPE_API = '/api-strip-integration/'; //get
export const NEXT_EVENTS_PATH = '/api-next-events/'; //get
export const GET_JOBS = '/api-jobs/'; //get
// export const LATEST_PRODUCTS = '/api-latest-products';
export const ONLINE_CONTENT = '/api-online-content'; ///get
export const CERTIFICATIONS = '/api-certifications-by-course'; //get
export const GET_INFORMATION_SYSTEM = '/api-information-system/'; //get
export const GET_CHALLENGE = '/api-challenge-only/'; //get
// export const FORM_IMG = '/api-form-image/';
// export const API_RESULT = '/api-result/';
export const API_SCALES = '/api-challenge-scales/'; //get
export const API_CHALLENGE_PRESENTATION = '/api-challenge-presentation/';
export const PUT_REGISTER_SOCIAL_PATH = '/api-virtual-campus-user-update-social/';
export const API_SOCIAL_MEDIA = '/api-social-media/';
export const API_JOB_QUESTION = '/api-job-filter-list/';
export const API_POSTULATION = '/api-postulation/';
export const API_VIRTUAL_CAMPUS_SPONSOR_MEDIA = '/api-virtual-campus-sponsor-media/';
export const API_PARAMETERS_COMPONENT = '/api-parameters-component';
export const API_ENGLISH_REGISTER = '/api-english-register/';
export const API_REGISTRATION_CHALLENGE_PRESENTATION = '/api-registration-challenge-presentation/';
export const API_GET_RESUME = '/api-resume/';
export const API_REGISTRATION_CHALLENGE_RESULT = '/api-registration-challenge-result/';
export const API_LEADER_TESTIMONIAL = '/api-leader-testimonial/';
export const API_SYNCRONOUSCLASS_VIEW = '/api-synchronousclass-api-view/';
export const API_ABOUT_COURSE = '/api-about-course/';
export const API_INFO_FILIAL = '/api-info-filial';
export const API_SCHEDULER_INFO = '/api-language-scheduler-info/';
export const API_USER_PHONE = '/api-user-phone/';
export const API_USER_SKILLS = '/api-user-skills/';
export const API_USER_DOCUMENT_TYPE = '/api-user-document-type/';
export const API_USER_REFERENCE = '/api-user-reference/';
export const API_USER_ENROLLMENT = '/api-user-enrollment/';
export const API_DOCUMENT_TYPE_COUNTRIES = '/api-document-type-countries/';
export const API_GET_SKILLS = '/api-skills/';
export const API_GET_NAME_SCHEDULER = '/api-scheduler-name/';
export const API_PERSONAL_DATA = '/api-personal-data/';
export const API_CREATE_USER = '/create-user-validated/';
export const API_REGISTER = '/api-agenda-generals/';
export const API_MEMBERSHIPS_DETAILS = '/api-membership-price-detail/';
export const API_MAIN_PARAMETER = '/api-main-parameters-component/';
export const API_PARAMETER_MULTILINGUAGE = '/api-parameters-multilanguage/';
export const API_BANNER_INTRODUCTORY_ACTICLE = '/api-virtual-campus-introductory-article/';
export const API_INSIGNIA_BADGED_IMAGE = '/api-insignia-badge-image/';
export const API_PREVIEW_CLIP = '/api-course-clip-preview/';
export const API_SHOW_REGISTER_FORM = '/api-show-register-form/';
export const API_TOKEN = '75853e402747730aaffc3c3b2fbb687cc46111da';

//IMPORTANT: Comment or uncomment BACKEND_URL for to deploy

export const BACKEND_URL = 'https://backend.litethinking.com';
// export const BACKEND_URL = 'https://pre-backend.litethinking.com';
let FRONTEND_URL; //comment for local
let FRONTENDLMS_URL;
let FRONTMICROSITE;
let CREDENTIALS_URL;
let JOBS_URL;

//this function load the url for environment
export const loadEnvironmentUrls = async () => {
  try {
    const data = await HttpClient.GET(GET_INFORMATION_SYSTEM);

    const resLms = data.content.find((system) => system.id === ID_SYSTEM_INFORMATION.LMS);
    const resCredentials = data.content.find(
      (system) => system.id === ID_SYSTEM_INFORMATION.CREDENTIAL
    );
    const resJobs = data.content.find((system) => system.id === ID_SYSTEM_INFORMATION.JOBS);
    const resPortal = data.content.find((system) => system.id === ID_SYSTEM_INFORMATION.PORTAL);
    const resOnlinePay = data.content.find(
      (system) => system.id === ID_SYSTEM_INFORMATION.ONLINE_PAY
    );

    FRONTENDLMS_URL = resLms.url || FRONTENDLMS_URL;
    CREDENTIALS_URL = resCredentials.url || CREDENTIALS_URL;
    JOBS_URL = resJobs.url || JOBS_URL;
    FRONTEND_URL = resPortal.url || FRONTEND_URL; //comment for local
    FRONTMICROSITE = resOnlinePay.url || FRONTMICROSITE;
  } catch (error) {
    console.error('Error loading environment URLs:', error);
  }
};

export { FRONTEND_URL, FRONTENDLMS_URL, CREDENTIALS_URL, JOBS_URL, FRONTMICROSITE };

// export { FRONTENDLMS_URL, CREDENTIALS_URL, JOBS_URL, FRONTMICROSITE }; //Uncomment for local
// export const FRONTEND_URL = 'http://localhost:3000';
