export const SENTRY_URL =
  'https://6198d2e486ab4ffb8837873d731b73f1@o463192.ingest.sentry.io/5481558';

export const STRATEGIC_LINES_VIEWS = {
  ORGANIZATIONAL_CONSULTING: 'organizational-consulting',
  TI_SOLUTIONS: 'ti-solutions',
  TRAININGS: 'trainings',
  SERVICES: 'services'
};

export const FRONTEND_INFO_DOCUMENTS = {
  POLICIES_OF_USE: {
    DOCUMENT_ID: 1,
    DOCUMENT_CODE: 'USER_TERM',
    DOCUMENT_NAME: 'términos de uso'
  },
  PRIVACITY_POLICIES: {
    DOCUMENT_ID: 2,
    DOCUMENT_CODE: 'PRIV_POLI',
    DOCUMENT_NAME: 'privacidad'
  }
};

export const PARAMETER_CODES = {
  LITE_THINKING_SCREEN_BANNER: 'LT_BANNER',
  LITE_THINKING_SCREEN_ABOUT_US: 'ABOUT_US',
  LITE_THINKING_SCREEN_PURPOSE: 'PURPOSE',
  LITE_THINKING_SCREEN_MISSION: 'MISSION',
  LITE_THINKING_SCREEN_VISION: 'VISION',
  LITE_THINKING_SCREEN_PRINCIPLES: 'PRINCIPIOS',
  LITE_THINKING_SCREEN_QA_POLITICS: 'QA_POLIS'
};

export const CONSULTANT_TYPES = {
  INTERNAL: 'I',
  EXTERNAL: 'E'
};

export const COMPANIES_TYPES = {
  TYPE_STRATEGIC_ALLIANCE: 1,
  TYPE_CLIENT: 2
};

export const COURSE_ELEMENT_TYPE = {
  TYPE_COURSE: 1,
  TYPE_NEWS: 2,
  TYPE_VIDEO: 3,
  TYPE_WEBINAR: 4
};

export const BANNER_ELEMENT_TYPE = {
  BANNER_IMAGE: 1,
  BANNER_VIDEO: 2,
  BANNE_LMS: 13,
  BANNE_VIDEO_ESP: 'Español',
  BANNE_VIDEO_ENG: 'English'
};

export const FRONTEND_STRATEGIC_LINES_VIEWS = {
  ORGANIZATIONAL_CONSULTING: 'organizational-consulting',
  TI_SOLUTIONS: 'ti-solutions',
  TRAININGS: 'trainings'
};

export const LOGIN_STATUS_CODE_PARAMETERS = {
  401: 'ERROR_LOGIN',
  400: 'ERROR_SYSTEM',
  404: 'ERROR_SYSTEM',
  500: 'ERROR_SYSTEM'
};

// * CONSTANTS FROM OLD LMS VERSION
export const MENU_ID = {
  LOGOUT: 130,
  UNIVERSITY: '/course',
  MY_CONTENT: '/my-content',
  MY_CHALLENGES: '/challenges',
  POTENTIAL_CLIENTS: '/customers',
  COMPANY_DETAIL_SCREEN: '/company-detail-screen'
};

// * Refactor parameteres according context e.g - group by screens - general parameters - etc

export const PARAMETERS_GENERAL = {
  KNOW_MORE: 'KNOW_MORE'
};

export const PARAMETERS_FORUM = {
  LMS_FSMAIN: 'LMS_FSMAIN',
  LMS_CMWRT: 'LMS_CMWRT'
};

export const PARAMETERS_COURSE = {
  LMS_CRSMAIN: 'LMS_CRSMAIN'
};

export const PARAMETERS_INTEREST_AREA = {
  LMS_IRA: 'LMS_IRA'
};

// url extensions in black list

export const URL_EXTENSION_BLACK_LIST = {
  RESOURCE: 'resourse_'
};

// course modes
export const COURSE_MODE = {
  ONLINE: '1',
  TELEPRESENCE: '2'
};

// role
export const ROLE = {
  PREMIUM: 1,
  CONSULTANT_EXTERNAL: 2,
  COLABORATOR: 3,
  ADMIN: 4,
  SPONSOR: 5,
  CONSULTANT: 6,
  BASIC: 7,
  LITE: 8,
  STANDARD: 9,
  GOLD: 10,
  COURTESY: 11
};

// status code
export const STATUS_CODE = {
  HTTP_404_NOT_FOUND: 404,
  HTTP_200_OK: 200,
  HTTP_500_INTERNAL_SERVER_ERROR: 500
};

// error types
export const FETCH_TYPE_ERRORS = {
  REQUEST_ERROR: 'REQUEST_ERROR'
};

/**
 * fetch black list codes
 * Status codes that will trigger
 */
export const FETCH_WHITE_LIST = [
  STATUS_CODE.HTTP_500_INTERNAL_SERVER_ERROR,
  STATUS_CODE.HTTP_404_NOT_FOUND
];

// internal
/**
 *
 * @param {string} error
 * @param {string} message
 * @param {string} path
 * @param {number} status
 * @returns General fetch error format
 */
const FETCH_ERRORS_DATA = (error, message, path, status) => ({
  error,
  message,
  path,
  status
});

// fetch errors messages
export const FETCH_ERRORS = {
  MAX_ITERATION: function (path, status) {
    return FETCH_ERRORS_DATA(
      FETCH_TYPE_ERRORS.REQUEST_ERROR,
      'Max iteration count reached',
      path,
      status
    );
  },
  IN_THE_WHITE_LIST: function (path, status) {
    return FETCH_ERRORS_DATA(
      FETCH_TYPE_ERRORS.REQUEST_ERROR,
      'Status code is not in the white list',
      path,
      status
    );
  }
};

// pagination limit
export const PAGINATION_LIMIT = 6;

// program content type
export const PROGRAM_CONTENT_TYPE = {
  // role user: trainings
  1: {
    PATH: '/my-programs'
  },
  // role consultant: trainings
  2: {
    PATH: '/my-programs'
  },
  // role collaborator: inductions
  3: {
    PATH: '/my-inductions'
  }
};

export const REGISTER_STATUS_CODE_PARAMETERS = {
  401: 'ERROR_USER_EXIST',
  400: 'ERROR_SYSTEM',
  404: 'ERROR_PASSWORD',
  406: 'ERROR_CAPTCHA',
  500: 'ERROR_SYSTEM'
};

export const FILIAL = {
  SPN: 3,
  COL: 2,
  USA: 1
};

export const DIV_LIMIT_FILE = {
  divisor: 1000000,
  limit_mb: 3000000
};

export const PLATFORM = {
  ID_LMS: 1,
  ID_CREDENTIAL: 2,
  ID_JOBS: 3
};

// role Group
export const ROLE_GROUP = {
  MEMBERSHIPS: 1,
  CONSULTANTS_EXTERNALS: 2,
  COLLABORATORS: 3,
  ADMINISTRATION: 4
};

export const ID_SYSTEM_INFORMATION = {
  LMS: 1,
  CREDENTIAL: 2,
  JOBS: 3,
  PORTAL: 4,
  ONLINE_PAY: 5,
  MICRO_MEETS: 6
};

export const ZONE_ID = {
  LATAM: 1,
  EMEA: 2,
  NA: 3
};

export const SPONSOR = {
  LT: 1
};

export const UNDEFINED_PLATFORM = 'Lite Thinking';

export const ID_MULTILANGUGAE = {
  ESP: 2,
  ENG: 3
};
